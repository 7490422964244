<template>
  <div class="pageContol listWrap templateList formCom category1">
    <div class="framePage">
      <div class="framePage-title">
        <span>
            <em>当前位置：</em>
            <a href="javascript:;" @click="$router.back()">题库设置</a>
            <i>></i>
            <a href="javascript:;" class="cur-a">编辑</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-form ref="categorySettingFromRef" :model="categorySettingForm" :rules="categorySettingFromRules" label-width="120px">
              <h3>基本信息</h3>
              <el-form-item label="工种类别" prop="categoryName">
                <el-input v-model="categorySettingForm.categoryName" disabled></el-input>
              </el-form-item>
              <el-form-item label="显示图标" prop="showIcon" required>
                <el-input v-model="categorySettingForm.showIcon"></el-input>
              </el-form-item>
              <h3>售卖规则</h3>
              <el-form-item label="iOS价格(元)" prop="priceIos" required>
                <el-select  v-model="categorySettingForm.priceIos" placeholder="请选择">
                  <el-option v-for="item in IospriceList" :key="item.label" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="安卓价格(元)" prop="priceAndroid" required>
                <el-input v-model.number="categorySettingForm.priceAndroid" maxlength="2" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
              </el-form-item>
              <el-form-item label="小程序价格(元)" prop="priceMiniapp" required>
                <el-input v-model.number="categorySettingForm.priceMiniapp" maxlength="2" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
              </el-form-item>
              <el-form-item label="有效期(天)" prop="validNum" required>
                <el-input v-model.number="categorySettingForm.validNum" maxlength="5" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
              </el-form-item>
              <el-form-item label="免费试做(道)" prop="freeNum">
                <el-input v-model.number="categorySettingForm.freeNum" maxlength="2" show-word-limit oninput="value=value.replace(/[^\d.]/g,'')"></el-input>
              </el-form-item>
              <h3>是否显示</h3>
              <el-form-item label="显示/隐藏" prop="showed">
                <el-switch v-model="categorySettingForm.showed">
                </el-switch>
                <span style="margin-left: 5px">{{ categorySettingForm.showed ? '显示' : '隐藏' }}</span>
              </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="$router.back()">取 消</el-button>
              <el-button type="primary" @click="categoryEdit()">确 定</el-button>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "mini/categorySettingEdit",
  components: {
  },
  mixins: [],
  data() {
    const isSelect = (rule, value, callback) => {
      let isWrong = true;
      this.IospriceList.forEach(item => {
        if (item.value === value) {
          isWrong = false;
        }
      })
      if (isWrong) { //如果值不是下拉框里的，提示
        return callback(new Error("请正确选择分类"));
      } else {
        callback();
      }
    }
    return {
      IospriceList: [
        { value: 6, label: 6, },
        { value: 18, label: 18, },
        { value: 68, label: 68, },
        { value: 88, label: 88, },
        { value: 118, label: 118, },
        { value: 168, label: 168, },
        { value: 188, label: 188, },
        { value: 288, label: 288, },
        { value: 328, label: 328, },
        { value: 618, label: 618, },
        { value: 998, label: 998, },
      ],
      categorySettingForm: { },
      categorySettingFromRules: {
        showIcon: [
          {required: true, message: "请输入图标", trigger: "blur",},
        ],
        priceIos: [
          {required: true, message: "请选择正确的IOS价格", trigger: "blur", validator: isSelect},
        ],
        priceAndroid: [
          {required: true, message: "请输入安卓价格", trigger: "blur",},
        ],
        priceMiniapp: [
          {required: true, message: "请输入小程序价格", trigger: "blur",},
        ],
        validNum: [
          {required: true, message: "请输入有效天数", trigger: "blur",},
        ],
      }
    };
  },
  watch: {},
  created() {
    this.categorySettingForm = this.$route.query.categorySetting;
  },
  computed: {
  },
  methods: {
    categoryEdit() {
      this.$refs["categorySettingFromRef"].validate((valid) => {
        if (valid) {
          this.$post(
              "/miniapp/categorysetting/saveOrUpdate",
              this.categorySettingForm,
              3000,
              true,
              2
          ).then((res) => {
            this.$message({
              type: res.status == "0" ? "success" : "error",
              message: res.message ? res.message : "操作失败",
            });
            if (res.status == 0) {
              this.$router.push({
                path: "/web/miniApp/categorySetting",
                query: {
                  refresh: true,
                },
              });
            }
          }).catch(() => {
            return;
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  },
};
</script>
<style lang="less">
</style>
